import React from "react"
import HeroTemplate from "../hero/hero-template"
import Container from "@material-ui/core/Container"
import { BlogStyled } from "../../templates/styles"
import Button from "@material-ui/core/Button"
import SectionTitleDescriptionCta from "../section/section-title-description-cta"
import Section from "../../components/section/section"
import FeatureTabs from "./feature-tabs"
import Jumbo, { JumboItemContainer } from "../jumbotron/jumbotron"
import styled from "styled-components"
import Img from "gatsby-image"
import ReadMoreHexagon from "../buttons/read-more-hexagon"
import Hidden from "@material-ui/core/Hidden"
import FeatureTabDownloadContainer from "./feature-tabs-download"
import LinkExternalInternal from "../link/link"
import Link from "gatsby-link"

const XMPPItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s;
  justify-content: center;
  :hover {
    transform: scale(1.2);
  }
`

const Feature = props => {
  const post = props.data.markdownRemark
  const { cover } = post.frontmatter
  const mainFeatures = post.frontmatter.mainfeatures
  const details = post.frontmatter.details

  const JumboItemProducts = props => {
    return (
      <>
        {props.link && (
          <LinkExternalInternal to={props.link}>
            <XMPPItem>
              <img
                src={props.cover.children[0].fluid.src}
                style={{ width: 40, margin: 10 }}
                alt={props.title}
              />
              <div style={{ color: "white", margin: 15 }}>{props.title}</div>
            </XMPPItem>
          </LinkExternalInternal>
        )}
        {!props.link && (
          <XMPPItem>
            <img
              src={props.cover.children[0].fluid.src}
              style={{ width: 40, margin: 10 }}
              alt={props.title}
            />
            <div style={{ color: "white", margin: 15 }}>{props.title}</div>
          </XMPPItem>
        )}
      </>
    )
  }

  const features = (
    <>
      {mainFeatures.map(item => {
        const coverPathFeature = item.cover.children[0].fluid.src

        return (
          <>
            <Hidden only="xs">
              <Section
                containerStyle={{
                  marginTop: "2em",
                  marginBottom: 0,
                  padding: 0,
                }}
                background={<React.Fragment></React.Fragment>}
                right={
                  item.direction === "right" ? (
                    <img src={coverPathFeature} alt={item.title} />
                  ) : (
                    <SectionTitleDescriptionCta
                      title={item.title}
                      description={item.subtitle}
                      cta={
                        item.link && (
                          <ReadMoreHexagon
                            style={{ marginLeft: 30 }}
                            link={item.link}
                          />
                        )
                      }
                    />
                  )
                }
                left={
                  item.direction === "left" ? (
                    <img src={coverPathFeature} alt={item.title} />
                  ) : (
                    <SectionTitleDescriptionCta
                      title={item.title}
                      description={item.subtitle}
                      cta={
                        item.link && (
                          <ReadMoreHexagon
                            style={{ marginLeft: 30 }}
                            link={item.link}
                          />
                        )
                      }
                    />
                  )
                }
              />
            </Hidden>
            <Hidden smUp>
              <Section
                containerStyle={{
                  marginTop: "2em",
                  marginBottom: 0,
                  padding: 0,
                }}
                background={<React.Fragment></React.Fragment>}
                right={<img src={coverPathFeature} alt={item.title} />}
                left={
                  <SectionTitleDescriptionCta
                    title={item.title}
                    description={item.subtitle}
                    cta={
                      item.link && (
                        <ReadMoreHexagon
                          style={{ marginLeft: 30 }}
                          link={item.link}
                        />
                      )
                    }
                  />
                }
              />
            </Hidden>
          </>
        )
      })}
    </>
  )

  const description = (
    <Container maxWidth="md">
      <BlogStyled
        style={{ marginTop: 30 }}
        dangerouslySetInnerHTML={{
          __html: post.html,
        }}
      />
    </Container>
  )

  const download = (
    <Container maxWidth="md">
      <FeatureTabDownloadContainer details={details} />
    </Container>
  )

  const ITEMS = [features, description, download]

  return (
    <>
      <HeroTemplate
        background={
          <Img
            fluid={cover.children[0].fluid}
            style={{ position: "static", padding: 0 }}
          />
        }
      >
        <h1>{post.frontmatter.title}</h1>
        <h4>{post.frontmatter.subtitle}</h4>
        <div>
          <Link to="/pricing-xmpp">
            <Button variant="contained" color="primary">
              Check our pricing
            </Button>
          </Link>
        </div>
        <Link to="/contact-sales" style={{ fontSize: "0.7em" }}>
          Contact our sales team
        </Link>
      </HeroTemplate>
      <FeatureTabs
        details={details}
        items={ITEMS}
        shortDescription={post.frontmatter.description}
      />
      <Jumbo
        title={
          <div>
            {post.frontmatter.features_bottom_title}{" "}
            {post.frontmatter.features_bottom_title_link && (
              <Link
                style={{ fontSize: "0.6em" }}
                to={post.frontmatter.features_bottom_title_link}
              >
                read more
              </Link>
            )}
          </div>
        }
        section={
          <JumboItemContainer
            items={post.frontmatter.features_bottom}
            component={<JumboItemProducts />}
          />
        }
      />
    </>
  )
}

export default Feature
