import React from "react"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"

const FeatureTabDownload = styled.div`
  margin: 10px 0px;
  border-radius: 20px;
  padding: 20px 10px 20px 20px;
  background-color: rgb(242, 242, 242);
  margin-top: 2em;
  margin-bottom: 2em;
`

const FeatureTabDownloadTitle = styled.h1`
  margin-bottom: 0.4em;
  color: ;
`

const FeatureTabVersion = styled.h4``

const FeatureTabDownloadButton = styled.a`
  font-family: "icomoon";
  background-color: #556a71;
  color: #ffffff;
  padding: 8px 10px;
  font-size: 0.8em;
  font-weight: bold;
  border-radius: 10px;
  margin: 5px 10px;
  &:hover {
    cursor: pointer;
  }
`

const FeatureTabDownloadSubtitle = styled.div`
  margin: 0 0 10px;
`

const FeatureTabDownloadContainer = props => {
  return (
    <FeatureTabDownload>
      {props.details.map(item => {
        return (
          <div key={item.description} style={{ marginBottom: "3em" }}>
            <FeatureTabDownloadTitle>{item.title}</FeatureTabDownloadTitle>
            <FeatureTabVersion>{item.version}</FeatureTabVersion>
            <FeatureTabDownloadSubtitle>
              {item.description}
            </FeatureTabDownloadSubtitle>
            <Grid container>
              {item.resources.map(resources => {
                return (
                  <FeatureTabDownloadButton
                    key={resources.link}
                    href={resources.link}
                  >
                    {resources.title}
                  </FeatureTabDownloadButton>
                )
              })}
            </Grid>
          </div>
        )
      })}
    </FeatureTabDownload>
  )
}

export default FeatureTabDownloadContainer
