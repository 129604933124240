import React from "react"
import Grid from "@material-ui/core/Grid"

const Section = props => {
  return (
    <section>
      <Grid
        container
        alignItems="center"
        style={{
          minHeight: 400,
          position: "relative",
          paddingTop: 80,
          paddingBottom: 80,
          marginTop: 80,
          marginBottom: 80,
          ...props.containerStyle,
        }}
        justify="center"
      >
        {props.background}
        {props.left && (
          <Grid
            item
            xs={12}
            sm={6}
            style={{ justifyContent: "center", display: "flex" }}
          >
            {props.left}
          </Grid>
        )}
        {props.right && (
          <Grid
            item
            xs={12}
            sm={6}
            style={{ justifyContent: "center", display: "flex" }}
          >
            {props.right}
          </Grid>
        )}
        {props.center && (
          <Grid
            item
            xs={12}
            style={{ justifyContent: "center", display: "flex" }}
          >
            {props.center}
          </Grid>
        )}
      </Grid>
    </section>
  )
}

export default Section
