import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Typography from "@material-ui/core/Typography"
import styled from "styled-components"
import Container from "@material-ui/core/Container"

const StyledShortDescription = styled.p`
  font-family: "Fira Sans";
  font-weight: 500;
  font-size: 1em;
  -webkit-letter-spacing: 0.1em;
  -moz-letter-spacing: 0.1em;
  -ms-letter-spacing: 0.1em;
  letter-spacing: 0.1em;
  line-height: 1.3em;
  text-align: center;
  color: #3e4346;
  margin-right: 20%;
  margin-left: 20%;
  margin-top: 3em;

  @media (max-width: 700px) {
    margin-right: 5%;
    margin-left: 5%;
  }
`

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
})

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </Typography>
  )
}

const CenteredTabs = props => {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Paper className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Features" />
          <Tab label="Details" />
          <Tab label="Download" />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <StyledShortDescription>
          {props.shortDescription}
        </StyledShortDescription>
      </TabPanel>
      <Container maxWidth="md">
        {props.items.map((item, index) => {
          return (
            <TabPanel key={index} value={value} index={index}>
              {item}
            </TabPanel>
          )
        })}
      </Container>
    </>
  )
}

export default CenteredTabs
