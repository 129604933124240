import React from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/layout"
import SEO from "../components/seo"
import Feature from "../components/feature/feature"

class FeaturePostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const { cover } = post.frontmatter
    const coverPath = cover && cover.children[0].fluid.src

    return (
      <Layout>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          image={coverPath}
        />
        <Feature {...this.props} />
      </Layout>
    )
  }
}

export default FeaturePostTemplate

export const pageQuery = graphql`
  query FeaturePostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        subtitle
        description
        features_bottom_title
        features_bottom_title_link
        details {
          title
          version
          description
          resources {
            link
            title
          }
        }
        features_bottom {
          title
          link
          cover {
            children {
              ... on ImageSharp {
                fluid(maxWidth: 150) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        mainfeatures {
          title
          subtitle
          direction
          link
          cover {
            children {
              ... on ImageSharp {
                fluid(maxWidth: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        cover {
          children {
            ... on ImageSharp {
              fluid(maxWidth: 1200, maxHeight: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
